<template lang="pug">
.new-campaign-flow-branching.text-center
  om-heading(h2) {{ $t('newCampaignFlow.branching.title') }}
  BoxSelect(:items="options" @select="onSelect")
</template>

<script>
  export default {
    components: {
      BoxSelect: () => import('@/components/Elements/BoxSelect/List.vue'),
    },
    computed: {
      options() {
        return ['popup', 'optimize', 'library'].map((key) => ({
          value: key,
          image: require(`@/assets/admin/svg/newCampaignFlow/${key}.svg`),
          title: `newCampaignFlow.branching.options.${key}.title`,
          description: `newCampaignFlow.branching.options.${key}.description`,
        }));
      },
    },
    methods: {
      onSelect(value) {
        this.$emit('select', value);
      },
    },
  };
</script>

<style lang="sass">
  .new-campaign-flow-branching
    .heading
      margin-bottom: 3.5rem
</style>
